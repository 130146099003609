import { useQuery } from "@tanstack/react-query";

import { getDepositHistory } from "requests";
import { ViewContent } from "components/shared/ViewContent";

export const DepositHistory = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["depositTrans"],
    queryFn: getDepositHistory,
  });

  return (
    <div>
      <div className="flex items-center justify-between mb-5">
        <h3 className="text-lGrey font-semibold mb-5 md:mb-0">
          Deposit history
        </h3>
      </div>
      <div>
        <div className="hidden md:grid grid-cols-2 pb-5">
          <div className="text-grey font-semibold text-xs col-span-1">
            Amount
          </div>
          <div className="text-grey font-semibold text-xs col-span-1">Date</div>
        </div>
        <div className="max-h-[536px] overflow-y-auto pr-5">
          <ViewContent
            isError={isError}
            isLoading={isLoading}
            data={data && data.data ? [data.data] : []}
          >
            {(data: any) =>
              data[0].map((depHistory: any) => (
                <div
                  key={depHistory.id}
                  className="py-4 border-t border-lightBlack grid grid-cols-2 md:grid-cols-2 gap-5"
                >
                  <div className="col-span-1">
                    <h3 className="text-main font-semibold text-sm">
                      $ {depHistory.amount}
                    </h3>
                  </div>

                  <div className="col-span-2 md:col-span-1 text-right md:text-left">
                    <p className="text-lGrey font-semibold text-sm">
                      {depHistory.createdAt}
                    </p>
                  </div>
                </div>
              ))
            }
          </ViewContent>
        </div>
      </div>
    </div>
  );
};
