import { Loader } from "components/shared/Loader";
import Cookie from "js-cookie";
import { Auth } from "types";

enum SocialAuthAction {
  LOGIN = "login",
  FA = "2fa",
  REGISTER = "register",
}

export const loader = async ({ request }: { request: any }) => {
  const params = window.location.search.replace("?", "").split("&");

  const action = params[0].replace("action=", "");

  if (SocialAuthAction.LOGIN === action) {
    const token = params[1].replace("token=", "");
    const refreshToken = params[2].replace("refresh_token=", "");

    Cookie.set(Auth.TOKEN, token);
    Cookie.set(Auth.REFRESH, refreshToken);

    window.location.replace("/");
    return {};
  }

  if (SocialAuthAction.FA === action) {
    const token = params[1].replace("token=", "");

    Cookie.set(Auth.TOTP, token);
    window.location.replace("/");
    return {};
  }

  if (SocialAuthAction.REGISTER === action) {
    const token = params[1].replace("token=", "");

    window.location.replace(`/auth/sign-up/step-two?token=${token}`);
    return {};
  }
  // loaders can be async functions

  return {};
};

export const SNLogin = () => {
  return (
    <div className="flex justify-center items-center min-h-screen bg-black">
      <Loader className="!bg-white" />
    </div>
  );
};
