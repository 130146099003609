import { RootRouter } from "router";

import { Slide, ToastContainer } from "react-toastify";
import { useIdleTimer } from "react-idle-timer";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import { Loader } from "components/shared/Loader";
import { SNLogin, loader } from "pages/auth/Sn-Login.page";
import { clearSession } from "utils";

// import { createWeb3Modal } from "@web3modal/wagmi/react";
// import { createConfig, http } from "wagmi";
// import { WagmiProvider } from "wagmi";
// import { mainnet, sepolia } from "wagmi/chains";
// import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { WagmiProvider, createConfig, http } from "wagmi";
import { mainnet, bsc } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";

const projectId = "5b86d63e059e396ce38684e72b6d7410";

const config = createConfig(
  getDefaultConfig({
    // Your dApps chains
    chains: [bsc],
    transports: {
      // RPC URL for each chain
      // [mainnet.id]: http(
      //   `https://eth-mainnet.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_ID}`
      // ),
      [bsc.id]: http("https://bsc-dataseed.binance.org/"),
    },

    // Required API Keys
    walletConnectProjectId: projectId,

    // Required App Info
    appName: "SmartX",

    // Optional App Info
    appDescription: "SmartX",
    appUrl: "https://dashboard.smart-x.world/favicon.ico", // your app's url
    appIcon: "https://dashboard.smart-x.world/icons/logo.svg", // your app's icon, no bigger than 1024x1024px (max. 1MB)
  })
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const router = createBrowserRouter([
  { path: "*", Component: RootRouter },
  // social login redirect logic
  { path: "/auth/sn_login", Component: SNLogin, loader },
]);

function App() {
  const handleOnIdle = () => {
    clearSession();
  };

  useIdleTimer({
    onIdle: handleOnIdle,
    timeout: 3600000,
    throttle: 1500,
  });

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        {/* <MetaMaskUIProvider
          sdkOptions={{
            dappMetadata: {
              name: "SmartX",
              url: window.location.href,
            },
            // infuraAPIKey: process.env.REACT_APP_API_INFURA_API_KEY,
          }}
        ></MetaMaskUIProvider> */}
        <ConnectKitProvider>
          <ToastContainer
            transition={Slide}
            position="bottom-right"
            autoClose={2000}
            icon={false}
            closeButton={true}
            draggable={false}
            className={"bg-black"}
            theme="dark"
          />
          <RouterProvider router={router} fallbackElement={<Loader />} />
        </ConnectKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
