import { useAuth } from "hooks";
import { AxiosResponse } from "axios";
import {
  createContext,
  FC,
  useMemo,
  useContext,
  useEffect,
  useCallback,
} from "react";
import {
  UseMutateFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { toast } from "react-toastify";

import {
  getUser,
  updateUserInfo,
  updateUserAvatar,
  getAppSettings,
} from "requests";
import { UpdateUserProps, UserProps } from "types";
import { errorParser } from "utils";
import Web3 from "web3";
import { useAccount, useWalletClient } from "wagmi";
import apiInstance from "api";

const ABIDeposit = [
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [{ name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "_upgradedAddress", type: "address" }],
    name: "deprecate",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "_spender", type: "address" },
      { name: "_value", type: "uint256" },
    ],
    name: "approve",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "deprecated",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "_evilUser", type: "address" }],
    name: "addBlackList",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "_from", type: "address" },
      { name: "_to", type: "address" },
      { name: "_value", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "upgradedAddress",
    outputs: [{ name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "", type: "address" }],
    name: "balances",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "maximumFee",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "_totalSupply",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [],
    name: "unpause",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "_maker", type: "address" }],
    name: "getBlackListStatus",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      { name: "", type: "address" },
      { name: "", type: "address" },
    ],
    name: "allowed",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "paused",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "who", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [],
    name: "pause",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "getOwner",
    outputs: [{ name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "owner",
    outputs: [{ name: "", type: "address" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [{ name: "", type: "string" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "_to", type: "address" },
      { name: "_value", type: "uint256" },
    ],
    name: "transfer",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      { name: "newBasisPoints", type: "uint256" },
      { name: "newMaxFee", type: "uint256" },
    ],
    name: "setParams",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "amount", type: "uint256" }],
    name: "issue",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "amount", type: "uint256" }],
    name: "redeem",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      { name: "_owner", type: "address" },
      { name: "_spender", type: "address" },
    ],
    name: "allowance",
    outputs: [{ name: "remaining", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "basisPointsRate",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "", type: "address" }],
    name: "isBlackListed",
    outputs: [{ name: "", type: "bool" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "_clearedUser", type: "address" }],
    name: "removeBlackList",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "MAX_UINT",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: false,
    inputs: [{ name: "_blackListedUser", type: "address" }],
    name: "destroyBlackFunds",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { name: "_initialSupply", type: "uint256" },
      { name: "_name", type: "string" },
      { name: "_symbol", type: "string" },
      { name: "_decimals", type: "uint256" },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, name: "amount", type: "uint256" }],
    name: "Issue",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, name: "amount", type: "uint256" }],
    name: "Redeem",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, name: "newAddress", type: "address" }],
    name: "Deprecate",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, name: "feeBasisPoints", type: "uint256" },
      { indexed: false, name: "maxFee", type: "uint256" },
    ],
    name: "Params",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, name: "_blackListedUser", type: "address" },
      { indexed: false, name: "_balance", type: "uint256" },
    ],
    name: "DestroyedBlackFunds",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, name: "_user", type: "address" }],
    name: "AddedBlackList",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, name: "_user", type: "address" }],
    name: "RemovedBlackList",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: "owner", type: "address" },
      { indexed: true, name: "spender", type: "address" },
      { indexed: false, name: "value", type: "uint256" },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, name: "from", type: "address" },
      { indexed: true, name: "to", type: "address" },
      { indexed: false, name: "value", type: "uint256" },
    ],
    name: "Transfer",
    type: "event",
  },
  { anonymous: false, inputs: [], name: "Pause", type: "event" },
  { anonymous: false, inputs: [], name: "Unpause", type: "event" },
];

const ABIAllocate = [
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "AmountLessThanMinimalDeposit",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "Deposit",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferStarted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "Withdrawal",
    type: "event",
  },
  {
    inputs: [],
    name: "acceptOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "depositAllowedTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
    ],
    name: "withdrawFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract IERC20",
        name: "_token",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "FEE_PERCENTAGE",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "MINIMAL_DEPOSIT",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "pendingOwner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "SCALE_FACTOR",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "token",
    outputs: [
      {
        internalType: "contract IERC20",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

interface UserContextProps {
  user: UserProps | null;
  isProfileLoading: boolean;
  profileError: any;
  updateUser: UseMutateFunction<
    AxiosResponse<any, any>,
    unknown,
    Partial<UpdateUserProps>,
    unknown
  >;
  isUserUpdating: boolean;
  updatingError: any;
  isUserAvatarUpdating: boolean;
  updatingAvatarError: any;
  mutateUserAvatar: UseMutateFunction<
    AxiosResponse<any, any>,
    unknown,
    Partial<any>,
    unknown
  >;
  depositFunds: any;
  appSettings: {
    smartContractAddress: string;
    walletAddress: string;
  };
  isAppSettingsLoading: boolean;
}

const initialCtx = {
  user: null,
  isProfileLoading: false,
  profileError: null,
  updateUser: () => {},
  isUserUpdating: false,
  updatingError: null,
  isUserAvatarUpdating: false,
  updatingAvatarError: null,
  mutateUserAvatar: () => {},
  tron: null,
  tronLoading: true,
  depositFunds: () => {},
  appSettings: {
    smartContractAddress: "",
    walletAddress: "",
  },
  isAppSettingsLoading: true,
};

export const UserContext = createContext<UserContextProps>(initialCtx);

export const UserContextProvider: FC<any> = ({ children }) => {
  const walletClient = useWalletClient();

  const queryClient = useQueryClient();
  const { token } = useAuth();
  const { address } = useAccount();

  const {
    data: user,
    isLoading: isProfileLoading,
    error: profileError,
    refetch,
  } = useQuery({
    queryKey: ["user"],
    queryFn: getUser,
    enabled: !!token,
  });
  useEffect(() => {
    console.log(walletClient, "before if.");
    if (walletClient.isError) {
      console.log("refetch me??");
      walletClient.refetch();
    }
  }, [walletClient]);

  const { data: appSettings, isLoading: isAppSettingsLoading } = useQuery({
    queryKey: ["applicationSettings"],
    queryFn: getAppSettings,
  });

  const {
    isLoading: isUserUpdating,
    error: updatingError,
    mutate: updateUser,
  } = useMutation({
    mutationFn: updateUserInfo,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ["user"], exact: true });

      toast(data.message);
    },
    onError: (error) => {
      // @ts-expect-error
      toast(errorParser(error) ?? "Error while making request");
    },
  });

  const {
    isLoading: isUserAvatarUpdating,
    error: updatingAvatarError,
    mutate: mutateUserAvatar,
  } = useMutation({
    mutationFn: updateUserAvatar,
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries({ queryKey: ["user"], exact: true });
      toast(data.message);
      refetch();
    },
    onError: () => {
      toast("Error while updating user, please, try later");
    },
  });

  const depositFunds = useCallback(
    async (amount: number) => {
      try {
        const currentUserFunds = await apiInstance.get(
          `/profile/getTRC20Balance`
        );
        const balance = currentUserFunds.data.balance / 1000000;

        if (balance < 10 || amount > balance) {
          toast.error("Wrong amount entered");
          return;
        }

        console.log(walletClient, "wallet client what is this?");
        console.log("before", walletClient.data?.transport, walletClient.data);
        console.log("window ethereum", window.ethereum);
        if (walletClient.data?.transport) {
          console.log("walletClient.data?.transport");
          const web3 = new Web3(walletClient.data.transport);

          const contract = new web3.eth.Contract(
            ABIDeposit,
            appSettings.walletAddress
          );
          // const contract = new web3.eth.Contract(
          //   ABIDeposit,
          //   "0x55d398326f99059ff775485246999027b3197955"
          // );

          const approve = await contract.methods.approve(
            appSettings.smartContractAddress,
            // "0x15BF0BAA3F2DB5A32b0352770aF5C88945d9453E",
            amount * Math.pow(10, 6)
          );
          const dataDeposit = await approve.encodeABI();

          console.log("before window.ethereum.request");

          const txDeposit = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [
              {
                to: appSettings.walletAddress,
                // to: "0x55d398326f99059ff775485246999027b3197955",
                from: address,
                // gas: "0x76c0",
                value: "0x0",
                data: dataDeposit,
                gasPrice: "0x12a05f200",
              },
            ],
          });

          console.log(txDeposit, "txDeposit");

          const contractAllocateTokens = new web3.eth.Contract(
            ABIAllocate,
            appSettings.smartContractAddress
          );

          const deposit =
            await contractAllocateTokens.methods.depositAllowedTokens(
              amount * Math.pow(10, 6)
            );

          const dataAllocateDeposit = await deposit.encodeABI();

          console.log("before window.ethereum.request");

          const txDepositAllowedTokens = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [
              {
                to: appSettings.smartContractAddress,
                // to: "0x55d398326f99059ff775485246999027b3197955",
                from: address,
                // gas: "0x76c0",
                value: "0x0",
                data: dataAllocateDeposit,
                gasPrice: "0x12a05f200",
              },
            ],
          });

          console.log(
            txDepositAllowedTokens,
            "txDepositAllowedTokens after all"
          );

          if (txDepositAllowedTokens) {
            toast.success("Funds were deposited");
          } else {
            toast.error("Error whie sending funds");
          }
        }
      } catch (error) {
        console.error(error);
        toast.error("Error while depositing");
      }
    },
    [walletClient, address, appSettings]
  );

  const value = useMemo(
    () => ({
      user,
      isProfileLoading,
      profileError,
      updateUser,
      isUserUpdating,
      updatingError,
      isUserAvatarUpdating,
      updatingAvatarError,
      mutateUserAvatar,
      depositFunds,
      appSettings,
      isAppSettingsLoading,
    }),
    [
      user,
      isProfileLoading,
      profileError,
      updateUser,
      isUserUpdating,
      updatingError,
      isUserAvatarUpdating,
      updatingAvatarError,
      mutateUserAvatar,
      appSettings,
      isAppSettingsLoading,
      depositFunds,
    ]
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);
