import axios from "axios";

export const cryptocompareApi = axios.create({
  baseURL: "https://min-api.cryptocompare.com/data",
});

// https://min-api.cryptocompare.com/data/pricemultifull?fsyms=USD&tsyms=BTC,1INCH,LINK
// const formattedData = Object.entries(data ?? {}).map(([key, value]) => ({
//     name: key,
//     current_price: value[state.selectedCurrencySymbol]?.PRICE,
//     open_price: value[state.selectedCurrencySymbol]?.OPENDAY,
//     // should be zero, since it will be rewritten by columns array
//     price_diff: 0,
//   }));
