import { toast } from "react-toastify";

export const copyClipboard = (value: string) => {
  window.isSecureContext && navigator.clipboard
    ? navigator.clipboard.writeText(value)
    : fallbackCopy(value);

  toast("Content copied!");
};

const fallbackCopy = (text: string) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus({ preventScroll: true });
  textArea.select();
  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Error while copying.", err);
  }
  document.body.removeChild(textArea);
};
