import classNames from "classnames";
import { FC } from "react";

// TODO: add rest props
interface BoxProps {
  className?: string;
  children: any;
  onClick?: () => void;
}

export const Box: FC<BoxProps> = ({ className, children, onClick }) => {
  return (
    <div
      className={classNames(
        "p-5 rounded-xl border border-lightBlack",
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
