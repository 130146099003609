import { useUserContext } from "context/user.context";

export const ReferralSeniorPartner = () => {
  const { user } = useUserContext();

  return (
    <>
      <h3 className="text-lGrey font-semibold ">Your senior partner</h3>
      <div className="flex items-center justify-between">
        {user?.referred_by}
      </div>
    </>
  );
};
