import classNames from "classnames";
import { mobileMenuItems } from "const/mobileMenu";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router";

const MobileMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (link: string) => {
    navigate(link);
  };

  const isActivePage = useCallback(
    (link: string) => {
      return location.pathname.split("/")[1] === link.split("/")[1];
    },
    [location]
  );

  return (
    <div className="fixed bottom-[-1px]  flex w-full justify-between bg-mobile-menu-gradient px-5 pb-5 pt-10 xl:hidden z-40">
      {mobileMenuItems.map(({ link, icon, title }) => (
        <button
          key={title}
          onClick={() => handleClick(link)}
          className={classNames(
            "flex h-[56px] w-full shrink flex-col items-center gap-3 pt-[10px] text-xs font-semibold leading-tight text-grey",
            isActivePage(link) &&
              "border-t-[3px] border-t-main bg-mainBg text-lGrey"
          )}
        >
          <div
            className={classNames(
              "[&>svg]:fill-grey",
              isActivePage(link) && "[&>svg]:fill-lGrey"
            )}
          >
            {icon}
          </div>
          <span>{title}</span>
        </button>
      ))}
    </div>
  );
};

export default MobileMenu;
