export const ROUTES = {
  home: "/",
  auth: "/auth/",
  login: "/auth/login",
  signUpStepOne: "/auth/sign-up/step-one",
  signUpStepTwo: "/auth/sign-up/step-two",
  confirm: "/auth/confirm",
  forgotPassword: "/auth/forgot-password",
  forgotPasswordNew: "/auth/forgot-password/new",
  settings: "/settings",
  profile: "/profile",
  finance: "/finance",
  referral: "/affiliate",
  mine: "/mine",
};
