import { ResponseError } from "types";

export const errorParser = (error: ResponseError): string => {
  const _error = error.response?.data.error;

  if (typeof _error === "string") return _error;

  if (typeof _error === "object") {
    const listOfErrors: any = [];

    // @ts-expect-error
    if (!!_error.length) return _error[0];
    Object.entries(_error as any).map(([key, value]) => {
      // @ts-expect-error
      value.forEach((err) => listOfErrors.push(err));
    });

    return listOfErrors.join(" ");
  }

  return "Something went wrong, please, try later.";
};
