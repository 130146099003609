import { useNavigate } from "react-router-dom";

import { Button } from "components/shared/Button";
import { SocialAuth } from "components/SocialAuth";

const SignUp = () => {
  const navigate = useNavigate();

  const handleRedirectToLogin = () => {
    navigate("/auth/login");
  };

  return (
    <div className="w-[320px] md:w-auto">
      <a href="https://smart-x.world" rel="noreferrer" target="_blank">
        <img
          className="w-[52px] m-auto mb-8"
          src="/icons/logo.svg"
          alt="SmartX logo"
        />
      </a>

      <h3 className="text-center text-white text-[27px] mb-8 leading-8">
        Start Your
        <br />
        Investment Today
      </h3>

      <div className="[&>*]:mt-4 w-full flex items-center justify-center">
        <SocialAuth />
      </div>
    </div>
  );
};

export default SignUp;
