import { OverallBalance } from "components/OverallBalance";
import { DividendCountersList } from "components/DividendCountersList";
import { ReferralIncome } from "components/ReferralIncome";
import { TopReferrals } from "components/TopReferrals";
import { Box } from "components/shared/Box";

const Home = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
      <Box className="col-span-1 md:col-span-3">
        <OverallBalance />
      </Box>
      <Box className="col-span-1 md:col-span-1">
        <DividendCountersList />
      </Box>
      <Box className="col-span-1 md:col-span-2">
        <ReferralIncome />
      </Box>
      <Box className="col-span-1 md:col-span-2">
        <TopReferrals />
      </Box>
    </div>
  );
};

export default Home;
