import { useLocation } from "react-router";

import { FinanceTabType } from "types";

import { FinanceDefaultView } from "components/FinanceDefaultView";
import { FinanceDepositView } from "components/FinanceDepositView";

export const Finance = () => {
  const location = useLocation();
  const tabFromURL = location.search.replace("?type=", "");

  const renderLayout = () => {
    const layout = {
      [FinanceTabType.DEPOSIT]: <FinanceDepositView />,
      // [FinanceTabType.WITHDRAW]: <FinanceWithdrawView />,
    };

    return (
      // @ts-expect-error
      layout[tabFromURL] || <FinanceDefaultView />
    );
  };

  return renderLayout();
};
