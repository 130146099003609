import apiInstance from "api";

// add id here in order to search for specific referral
export const getReferrals = ({ queryKey }: any) => {
  const [_, payId] = queryKey;

  const params = payId ? `?payId=${payId}` : "";

  return apiInstance
    .get(`/profile/referral-tree${params}`)
    .then((res) => res.data);
};

export const getReferralsOverallMoneyFlow = () =>
  apiInstance.get("/profile/referral-tree/").then((res) => res.data);

export const getReferralStats = ({ queryKey }: any) => {
  const [_, startDate, endDate] = queryKey;
  return apiInstance
    .get(`/profile/referral-statistics`)
    .then((res) => res.data);
};

export const getActiveReferralStats = ({ queryKey }: any) => {
  const [_, startDate, endDate] = queryKey;

  return apiInstance
    .get(`/profile/active-referral-statistics`)
    .then((res) => res.data);
};

export const getReferralsHistory = ({ queryKey }: any) => {
  const [_, page, limit, status, level, searchParam] = queryKey;

  let url = `/profile/referral-list?page=${page}&limit=${limit}`;

  if (status) {
    url += `&status=${status}`;
  }

  if (level) {
    url += `&level=${level}`;
  }

  if (searchParam) {
    url += `&search=${searchParam}`;
  }

  return apiInstance.get(url).then((res) => res.data);
};

export const getReferralsEarningsHistory = ({ queryKey }: any) => {
  const [_, page, limit] = queryKey;

  // TODO: once BE is rdy, change this request url
  // ?page=${page}&limit=${limit}
  let url = `/profile/referral-accruals`;

  return apiInstance.get(url).then((res) => res.data);
};

export const getTopReferrals = () =>
  apiInstance.get(`/profile/top-referrals`).then((res) => res.data);

export const getReferralsEarnings = () =>
  apiInstance.get(`/profile/top-referrals`).then((res) => res.data);
