import Cookies from "js-cookie";

import { Auth } from "types";
import { clearSession, parseJwt } from "utils";

import { useAccount } from "wagmi";

export const useAuth = () => {
  const { connector } = useAccount();
  const token = Cookies.get(Auth.TOKEN) ?? "";
  const TwoFactToken = Cookies.get(Auth.TOTP);

  const tokenPayload = parseJwt(token);

  const handleExpiredSession = () => {
    clearSession();
  };

  const handleSignout = async () => {
    try {
      handleExpiredSession();
      await connector?.disconnect();
    } catch (error) {
      console.error(error, "error");
    }
  };

  return { tokenPayload, handleSignout, token, TwoFactToken };
};
