import apiInstance from "api";
import { UpdateUserProps } from "types";

export const getUser = () =>
  apiInstance.get("/profile").then((res) => res.data);

export const updateUserInfo = (requestBody: Partial<UpdateUserProps>) =>
  apiInstance.patch("/profile", requestBody);

export const updateUserAvatar = (requestBody: Partial<UpdateUserProps>) =>
  apiInstance.post("/profile/avatar", requestBody, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getSessions = () =>
  apiInstance.get("/sessions").then((res) => res.data);

export const changeProfilePassword = (requestBody: any) =>
  apiInstance
    .post("/profile/change-password", requestBody)
    .then((res) => res.data);

export const getBinaryStatsReferral = () =>
  apiInstance.get("/profile/leg-statistics").then((res) => res.data);
