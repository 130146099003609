import { Button } from "components/shared/Button";
import { useUserContext } from "context/user.context";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { unlockWithdrawProfit } from "requests";

export const OverallBalance = () => {
  const { user } = useUserContext();

  const { isLoading: isUnlockingProfit, mutate } = useMutation({
    mutationFn: unlockWithdrawProfit,
    onSuccess: () => {
      toast.success("Profit were withdrawn");
    },
    onError: (error) => {
      console.error(error);
      toast.error("Error while withdrawing");
    },
  });

  const handleWithdrawProfit = () => {
    mutate();
  };

  return (
    <>
      <div className="flex flex-col justify-between md:flex-row">
        <div>
          <h5 className="mb-2 font-bold text-lGrey">Overall Balance</h5>
          <div className="flex items-start">
            <span className="mr-2 text-2xl font-semibold text-main">$</span>
            <span className="text-2xl font-bold text-main">
              {user?.overallBalance}
            </span>
          </div>
        </div>
        <div className="mt-5 flex flex-col md:flex-row md:mt-0">
          <div className="flex min-w-[100px] flex-col md:min-w-[118px]">
            <h3 className="mb-[6px] text-sm font-semibold text-lGrey">
              Passive income earned:
            </h3>
            <p className="font-bold text-lGrey">${user?.passiveIncome}</p>
          </div>
          <div className="mx-2 h-[45px] w-[1px] bg-lightBlack md:mx-5" />
          <div className="flex min-w-[100px] flex-col md:min-w-[118px]">
            <h3 className="mb-[6px] text-sm font-semibold text-lGrey">
              Referral bonuses earned:
            </h3>
            <p className="font-bold text-lGrey">${user?.referralBonuses}</p>
          </div>

          {+(user?.availableForWithdraw ?? 0) > 10 && (
            <>
              <div className="mx-2 h-[45px] w-[1px] bg-lightBlack md:mx-5" />
              <div className="flex min-w-[100px] flex-col md:min-w-[118px]">
                <h3 className="mb-[6px] text-sm font-semibold text-lGrey">
                  Profit available for withdraw:
                </h3>
                <p className="font-bold text-lGrey">
                  ${user?.availableForWithdraw}
                </p>
                <Button
                  type="button"
                  className="bg-main  mt-5 font-semibold"
                  onClick={handleWithdrawProfit}
                  isLoading={isUnlockingProfit}
                >
                  Claim
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
