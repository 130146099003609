import apiInstance from "api";
import {
  VerifyPinProps,
  TOTPRequestProps,
  DefaultPinProps,
  Verify2faRequestProps,
} from "types";

export const setPin = (requestBody: VerifyPinProps) =>
  apiInstance
    .post("/profile/profile/set-pin", requestBody)
    .then((res) => res.data);

export const get2faInfo = () =>
  apiInstance.get("/profile/profile/generate-totp").then((res) => res.data);

export const setTOTP = (requestBody: TOTPRequestProps) =>
  apiInstance
    .post("/profile/profile/setup-totp", requestBody)
    .then((res) => res.data);

export const removeTOTP = (requestBody: DefaultPinProps) =>
  apiInstance
    .post("/profile/profile/disable-2fa", requestBody)
    .then((res) => res.data);

export const verifyPin = (requestBody: VerifyPinProps) =>
  apiInstance.post("/verify-pin", requestBody).then((res) => res.data);

export const check2fa = (requestBody: Verify2faRequestProps) =>
  apiInstance.post("/2fa_check", requestBody).then((res) => res.data);

export const removeSession = ({
  sessionId,
}: Partial<{ sessionId: number }>) => {
  return apiInstance.delete(`/sessions/${sessionId}`).then((res) => res.data);
};
