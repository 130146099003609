import apiInstance from "api";
import { WithdrawFundsRequestProps } from "types";

export const addFunds = (requestBody: any) =>
  apiInstance.post("/accounts/deposit", requestBody).then((res) => res.data);

export const confirmDeposit = ({ id }: any) =>
  apiInstance.get(`/accounts/transactions/${id}`).then((res) => res.data);

export const getBalances = () =>
  apiInstance.get("/accounts").then((res) => res.data);

export const getCurrency = ({ queryKey }: any) =>
  apiInstance.get(`/accounts/currencies`).then((res) => res.data);

export const getWithdrawHistory = ({ queryKey }: any) => {
  const [_, page, limit, type] = queryKey;
  return apiInstance
    .get(
      `/accounts/transactions?page=${page ?? 1}&limit=${
        limit ?? 10
      }&transactionType=${type}`
    )
    .then((res) => res.data);
};
// -----

export const withdrawFunds = (requestBody: WithdrawFundsRequestProps) =>
  apiInstance.post("/accounts/withdraw", requestBody).then((res) => res.data);

export const transferToDividend = (requestBody: { amount: number }) =>
  apiInstance
    .post("/investment/transfer-to-dividend", requestBody)
    .then((res) => res.data);

export const transferFromDividenToBalance = (requestBody: { amount: number }) =>
  apiInstance
    .post("/investment/transfer-from-dividend-to-balance", requestBody)
    .then((res) => res.data);

export const createInvestment = (requestBody: any) =>
  apiInstance
    .post(`/investment/create-investment`, requestBody)
    .then((res) => res.data);

export const activateInvestment = (requestBody: any) =>
  apiInstance
    .post(`/investment/activate-investment/${requestBody.id}`)
    .then((res) => res.data);

export const getActivatedDividendsBalances = () =>
  apiInstance.get("/investment/investments/all").then((res) => res.data);

export const transferToAnotherUser = (requestBody: any) =>
  apiInstance.post("/accounts/transfer", requestBody).then((res) => res.data);

export const getHistoryUserTransfers = ({ queryKey }: any) => {
  const [_, page, limit] = queryKey;
  return apiInstance
    .get(`/accounts/p2p-transfers?page=${page ?? 1}&limit=${limit}`)
    .then((res) => res.data);
};

// wtf if this
export const getInteresetAccruals = () => {
  return apiInstance
    .get(`/investment/interest-accruals`)
    .then((res) => res.data);
};

// investmentId
export const closeInvestment = (requestBody: any) =>
  apiInstance
    .post(`/investment/cancel-investment`, requestBody)
    .then((res) => res.data);

export const transferFromRefToBalance = (requestBody: any) =>
  apiInstance
    .post("/accounts/transfer-from-ref-to-balance", requestBody)
    .then((res) => res.data);

export const transferFromRefToDividend = (requestBody: any) =>
  apiInstance
    .post("/accounts/transfer-from-ref-to-dividend", requestBody)
    .then((res) => res.data);

export const allocateDeposit = (requestBody: any) =>
  apiInstance
    .post("/deposit/transferAllowedTokens", requestBody)
    .then((res) => res.data);

export const getTransactions = () =>
  apiInstance.get(`/profile/transactions`).then((res) => res.data);

export const claimProfit = () =>
  apiInstance.post("/profit/claim-profit").then((res) => res.data);

// made two separater in order to increase readability for future refence
export const getDepositHistory = () => {
  return apiInstance
    .get(`/profile/deposit-transactions`)
    .then((res) => res.data);
};

export const unlockWithdrawProfit = () =>
  apiInstance.post("/withdraw-profit").then((res) => res.data);
