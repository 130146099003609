import { WalletTypes } from "types";

export const balanceOptions = [
  {
    label: "Balance",
    value: WalletTypes.BALANCE,
  },
  {
    label: "Dividend",
    value: WalletTypes.DIVIDEND,
  },
];
