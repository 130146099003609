import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

interface CountdownProps {
  endTime: number | string;
}

export const Countdown = ({ endTime }: CountdownProps) => {
  const targetTime = dayjs(endTime);

  const [timeLeft, setTimeLeft] = useState(
    targetTime.diff(dayjs(), "millisecond")
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const now = dayjs();
      const millisecondsLeft = targetTime.diff(now, "millisecond");
      if (millisecondsLeft > 0) {
        setTimeLeft(millisecondsLeft);
      } else {
        clearInterval(interval);
        setTimeLeft(0);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [endTime, targetTime]);

  const hours = Math.floor((timeLeft % 86400000) / 3600000);
  const minutes = Math.floor((timeLeft % 3600000) / 60000);
  const seconds = Math.floor((timeLeft % 60000) / 1000);

  return (
    <span className="text-white">
      {hours.toString().padStart(2, "0")}:{minutes.toString().padStart(2, "0")}:
      {seconds.toString().padStart(2, "0")}
    </span>
  );
};
