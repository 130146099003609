export enum Auth {
  TOKEN = "token",
  USER = "user",
  PHPSESSID = "PHPSESSID",
  REFRESH = "refresh",
  TOTP = "2faToken",
}

export enum UserRoles {
  ROLE_USER = "ROLE_USER",
}

export interface TokenPayloadProps {
  iat: number;
  exp: number;
  roles: string[];
  username: string;
}
