import classNames from "classnames";
import "./loader.styles.css";
import { FC } from "react";

interface LoaderProps {
  className?: string;
}

export const Loader: FC<LoaderProps> = ({ className }) => {
  return <div className={classNames("loader", className && className)} />;
};
