import { Box } from "components/shared/Box";
import { Button } from "components/shared/Button";
import { useUserContext } from "context/user.context";
import { useNavigate } from "react-router";

export const DividendCountersList = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/finance?type=deposit");
  };

  function calculatePercentage(total: number, portion: any) {
    if (total === 0) {
      return 0; // To handle division by zero
    }
    return (portion / total) * 100;
  }

  return (
    <>
      <Button
        type="submit"
        className="bg-main mt-4 font-bold text-black mb-5 !text-2xl"
        onClick={handleClick}
      >
        Deposit
      </Button>
      <ul className="max-h-[277px] overflow-y-auto w-full">
        {+(user?.availableWithdrawalAmount ?? 0) > 0 && (
          <Box className="w-full bg-boxBg mt-5">
            <div className="flex items-center justify-between">
              <h5 className="text-sm text-lGrey font-semibold">
                Passive income received:
              </h5>
            </div>
            <div>
              <div className="flex items-center">
                <h5 className="text-lGrey font-semibold text-base">
                  $ {user?.alreadyWithdrawn}
                </h5>
              </div>
            </div>
            <div className="relative my-2 h-[3px]">
              <div
                className="absolute bg-main rounded-sm h-[3px]"
                style={{
                  width: `${calculatePercentage(
                    +(user?.availableWithdrawalAmount ?? 0),
                    +(user?.alreadyWithdrawn ?? 0)
                  )}%`,
                }}
              ></div>
              <div className="absolute bg-lMain w-full rounded-sm h-[3px]"></div>
            </div>
          </Box>
        )}
        <Button
          type="submit"
          className="bg-main mt-4 font-semibold text-black"
          disabled
        >
          Your LVL: TIER {user?.membershipStatus}
        </Button>
      </ul>
    </>
  );
};
