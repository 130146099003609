import classNames from "classnames";
import { useQuery } from "@tanstack/react-query";

import { useToggle } from "hooks";
import { getReferrals } from "requests";

import { UserInfoBlock } from "components/UserInfoBlock";
import { Box } from "components/shared/Box";
import { ChevronDown } from "components/shared/Icons";
import { Loader } from "components/shared/Loader";
import { ViewContent } from "components/shared/ViewContent";
import { useForm } from "react-hook-form";

const referralsDummyData = {
  id: 285,
  email: "sobchuk.artem.ltd+2.1@gmail.com",
  username: "noname",
  depth: 0,
  current: false,
  children: [
    {
      id: 317,
      email: "sobchuk.artem.ltd+1.3@gmail.com",
      username: "noname",
      depth: 1,
      current: false,
      children: [
        {
          id: 320,
          email: "sobchuk.artem.ltd+1.4@gmail.com",
          username: "noname",
          depth: 2,
          current: false,
          children: [
            {
              id: 321,
              email: "sobchuk.artem.ltd+1.5@gmail.com",
              username: "noname",
              depth: 3,
              current: true,
              children: [
                {
                  id: 325,
                  email: "sobchuk.artem.ltd+2.6@gmail.com",
                  username: "noname",
                  depth: 4,
                  current: false,
                  children: [],
                },
                {
                  id: 326,
                  email: "sobchuk.artem.ltd+3.6@gmail.com",
                  username: "noname",
                  depth: 4,
                  current: false,
                  children: [
                    {
                      id: 327,
                      email: "sobchuk.artem.ltd+1.7@gmail.com",
                      username: "noname",
                      depth: 5,
                      current: false,
                      children: [
                        {
                          id: 328,
                          email: "sobchuk.artem.ltd+1.8@gmail.com",
                          username: "noname",
                          depth: 6,
                          current: false,
                          children: [
                            {
                              id: 329,
                              email: "sobchuk.artem.ltd+1.9@gmail.com",
                              username: "noname",
                              depth: 7,
                              current: false,
                              children: [
                                {
                                  id: 330,
                                  email: "sobchuk.artem.ltd+1.10@gmail.com",
                                  username: "noname",
                                  depth: 8,
                                  current: false,
                                  children: [],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              id: 322,
              email: "sobchuk.artem.ltd+2.4@gmail.com",
              username: "noname",
              depth: 3,
              current: false,
              children: [],
            },
            {
              id: 323,
              email: "sobchuk.artem.ltd+3.5@gmail.com",
              username: "noname",
              depth: 3,
              current: false,
              children: [
                {
                  id: 324,
                  email: "sobchuk.artem.ltd+1.6@gmail.com",
                  username: "noname",
                  depth: 4,
                  current: false,
                  children: [],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 318,
      email: "sobchuk.artem.ltd+2.3@gmail.com",
      username: "noname",
      depth: 1,
      current: false,
      children: [],
    },
    {
      id: 319,
      email: "sobchuk.artem.ltd+3.3@gmail.com",
      username: "noname",
      depth: 1,
      current: false,
      children: [],
    },
  ],
};

const TreeNode = ({ node }: any) => {
  const [showChildrenR, toggleChildrenR] = useToggle();
  const [showChildrenL, toggleChildrenL] = useToggle();

  if (!node) return null;

  return (
    <div className="" style={{ marginLeft: 5 }}>
      <div className="flex justify-between bg-lightBlack cursor-pointer first:mt-0  p-4 rounded-2xl">
        <UserInfoBlock
          // showIcon={node.depth === 1}
          username={node.login}
          email={node.email}
        />
      </div>
      <div>
        <div
          onClick={toggleChildrenL}
          className={classNames(
            "flex justify-between items-center p-2 transition-all rounded-xl cursor-pointer",
            showChildrenL && "border border-main !bg-lMain"
          )}
        >
          <strong>Left Leg:</strong>
          <div
            className={classNames(
              "ml-[10px] transition-all",
              showChildrenL ? "rotate-0" : "rotate-180"
            )}
          >
            <ChevronDown />
          </div>
        </div>
        {showChildrenL && <TreeNode node={node.leftChild} />}
      </div>

      <div>
        <div
          onClick={toggleChildrenR}
          className={classNames(
            "flex justify-between items-center p-2 transition-all rounded-xl cursor-pointer",
            showChildrenR && "border border-main !bg-lMain"
          )}
        >
          <strong>Right Leg:</strong>
          <div
            className={classNames(
              "ml-[10px] transition-all",
              showChildrenR ? "rotate-0" : "rotate-180"
            )}
          >
            <ChevronDown />
          </div>
        </div>
        {showChildrenR && <TreeNode node={node.rightChild} />}
      </div>
    </div>
  );
};

// Tree component to display the entire tree
const Tree = ({ data }: any) => {
  return (
    <div>
      {data.map((rootNode: any, index: number) => (
        <TreeNode key={index} node={rootNode} />
      ))}
    </div>
  );
};

const ReferralsTree = ({ node }: { node: any }) => {
  const hasChildren = !!node.children.length;

  const [showChildren, toggleChildren] = useToggle();

  return (
    <div
      className={classNames(
        "flex flex-col [&>*]:mt-[10px] relative",
        node.depth === 1 ? "ml-0" : "md:ml-6"
      )}
    >
      {hasChildren ? (
        <>
          <Box
            className={classNames(
              "flex justify-between bg-lightBlack cursor-pointer first:mt-0 transition-all",
              showChildren && "border border-main !bg-lMain"
            )}
            onClick={toggleChildren}
          >
            <UserInfoBlock
              showIcon={node.depth === 1}
              username={node.username}
              email={node.email}
            />
            <h3 className="text-lGrey font-semibold text-sm">
              Level {node.depth}
            </h3>
            <div className="flex items-center">
              {node.depth < 4 && (
                <div>
                  <h3 className="text-lGrey text-sm font-semibold">59162 $</h3>
                  <h3 className="text-grey text-xs font-semibold">Value</h3>
                </div>
              )}

              <div
                className={classNames(
                  "ml-[10px] transition-all",
                  showChildren ? "rotate-0" : "rotate-180"
                )}
              >
                <ChevronDown />
              </div>
            </div>
          </Box>
          {showChildren &&
            node.children.map((childNode: any) => (
              <ReferralsTree key={childNode.id} node={childNode} />
            ))}
        </>
      ) : (
        <Box className="flex justify-between bg-lightBlack first:mt-0">
          <UserInfoBlock username={node.username} email={node.email} />
          <h3 className="text-lGrey font-semibold text-sm">
            Level {node.depth}
          </h3>
          <div className="flex items-center">
            <div>
              <h3 className="text-lGrey text-sm font-semibold">59162 $</h3>
              <h3 className="text-grey text-xs font-semibold">Value</h3>
            </div>
          </div>
        </Box>
      )}
    </div>
  );
};

export const ReferralTree = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["referrals"],
    queryFn: getReferrals,
  });

  if (isLoading) return <Loader className="!bg-white" />;

  return (
    <>
      <div className="flex flex-col md:flex-row md:items-center justify-between mb-5">
        <h3 className="text-lGrey font-semibold mb-5 md:mb-0">
          Referral structure{" "}
        </h3>
      </div>
      <div className="max-h-[640px] overflow-y-auto [&>*]:mt-[10px] pr-5">
        {/* TODO: pass data here from useQuery */}
        <ViewContent
          isLoading={isLoading}
          data={data && data.data ? [data.data] : []}
          isError={isError}
        >
          {(data: any) => {
            return <Tree data={data} />;
          }}
        </ViewContent>
      </div>
    </>
  );
};
