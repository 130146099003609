import { useNavigate } from "react-router";

import { Box } from "components/shared/Box";
import { DividendBonusesTicker } from "components/DividendBonusesTicker";
import { FinaceDetailedTable } from "components/FinanceDetailedTable";
import { Button } from "components/shared/Button";
import { Countdown } from "components/Countdown";
import { FinanceTabType } from "types";
import { useUserContext } from "context/user.context";
import { toast } from "react-toastify";
import classNames from "classnames";
import dayjs from "dayjs";
import apiInstance from "api";

export const FinanceDefaultView = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();

  const handleButtonNavigation = (url: string) => navigate(url);

  function calculatePercentage(total: number, portion: any) {
    if (total === 0) {
      return 0; // To handle division by zero
    }
    return (portion / total) * 100;
  }
  const targetTime = dayjs(user?.nextProfitClaimTime);
  const now = dayjs();

  const availableForClaim = targetTime.diff(now, "millisecond") < 0;

  return (
    <div className="grid grid-cols-1 gap-5 md:grid-cols-4">
      <Box
        className={
          classNames("col-span-1", +(user?.availableWithdrawalAmount ?? 0) > 0)
            ? "md:col-span-3"
            : "md:col-span-4"
        }
      >
        <div className="flex flex-col items-start justify-between md:flex-row">
          <div>
            <h5 className="mb-2 font-bold text-lGrey">Balance</h5>
            <div className="flex items-start">
              <span className="mr-2 text-2xl font-semibold text-main">$</span>
              <span className="text-2xl font-bold text-main">
                {user?.balanceAmount}
              </span>
            </div>
          </div>

          <div className="mt-5 flex w-full justify-between md:mt-0 md:w-auto">
            <Button
              text="Deposit"
              type="button"
              className="rounded-xl bg-main !p-4 !text-xl font-semibold text-black  md:w-[210px]"
              onClick={() =>
                handleButtonNavigation(`?type=${FinanceTabType.DEPOSIT}`)
              }
            />
          </div>
        </div>
      </Box>

      {
        <Box className="col-span-1 md:col-span-1">
          <h4 className="text-base text-lGrey">Counter passive income</h4>
          <div className="relative mt-4 h-[88px] rounded-lg border border-lightBorder">
            <DividendBonusesTicker />
          </div>

          {+(user?.availableWithdrawalAmount ?? 0) > 0 && (
            <>
              {" "}
              <Button
                type="button"
                className={classNames(
                  "border-main border bg-dMain md:mt-0 flex items-center justify-center text-main text-xl w-full !mt-5",
                  availableForClaim && "cursor-pointer"
                )}
                onClick={async () => {
                  try {
                    const res = await apiInstance.post(`/claim-profit`);
                    toast.success("Funds were claimed");
                    // if (res.data && res.data.result.code) {
                    //   toast.error("Error on claiming");
                    //   return;
                    // }

                    // const tx = await wallet.signTransaction(res.data);
                    // if (!tx) {
                    //   toast.error("Error while depositing");
                    //   return;
                    // } else {
                    //   const resFromDeposit = await apiInstance.post(
                    //     `/broadcast`,
                    //     tx
                    //   );

                    //   if (resFromDeposit.data.code) {
                    //     toast.error("Error while claiming");
                    //   } else {
                    //     toast.success("Successfuly claimed");
                    //     window.location.reload();
                    //   }
                    // }
                  } catch (error) {
                    console.error(error);
                    toast.error("Error while claiming");
                  }
                }}
                disabled={!availableForClaim}
              >
                {availableForClaim ? (
                  "Take profit"
                ) : (
                  <Countdown endTime={user!.nextProfitClaimTime} />
                )}
              </Button>
              <Box className="w-full bg-boxBg mt-5">
                <div className="flex items-center justify-between">
                  <h5 className="text-sm text-lGrey font-semibold">
                    Passive income received:
                  </h5>
                </div>
                <div>
                  <div className="flex items-center">
                    <h5 className="text-lGrey font-semibold text-base">
                      $ {user?.alreadyWithdrawn}
                    </h5>
                  </div>
                </div>
                <div className="relative my-2 h-[3px]">
                  <div
                    className="absolute bg-main rounded-sm h-[3px]"
                    style={{
                      width: `${calculatePercentage(
                        +(user?.availableWithdrawalAmount ?? 0),
                        +(user?.alreadyWithdrawn ?? 0)
                      )}%`,
                    }}
                  ></div>
                  <div className="absolute bg-lMain w-full rounded-sm h-[3px]"></div>
                </div>
              </Box>
            </>
          )}
        </Box>
      }

      <Box className="col-span-1 md:col-span-4">
        <FinaceDetailedTable />
      </Box>
    </div>
  );
};
