import { Box } from "components/shared/Box";
import { FC } from "react";
import { BinaryDataDto } from "types";

interface ReferralInfoBlockProps {
  data: BinaryDataDto;
}

export const ReferralInfoBlock: FC<ReferralInfoBlockProps> = ({ data }) => {
  return (
    <>
      <Box className=" bg-lightBlack border-none flex items-center justify-between">
        <h3 className=" text-lGrey font-semibold">Total referrals</h3>
        <h3 className=" text-lGrey font-semibold">{data.totalReferrals}</h3>
      </Box>
      <Box className="mt-[10px] bg-lightBlack border-none flex items-center justify-between">
        <h3 className=" text-lGrey font-semibold">Active referrals</h3>
        <h3 className=" text-lGrey font-semibold">
          {data.activeReferrals}{" "}
          <span className="text-sm text-grey font-semibold">
            ({data.activePercentage}% of total)
          </span>
        </h3>
      </Box>
      <Box className="mt-[10px] bg-lightBlack border-none flex items-center justify-between">
        <h3 className=" text-lGrey font-semibold">Turnover structure</h3>
        <h3 className=" text-lGrey font-semibold">${data.turnover}</h3>
      </Box>
    </>
  );
};
