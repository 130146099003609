import {
  createContext,
  FC,
  useMemo,
  useContext,
  useRef,
  useCallback,
} from "react";

interface WidgetContextProps {
  widgetRef: HTMLDivElement | null;
  initWidgetDiv: (widgetRef: any) => void;
}

const initialCtx = {
  widgetRef: null,
  initWidgetDiv: () => {},
};

export const WidghetContext = createContext<WidgetContextProps>(initialCtx);

export const WidgetContextProvider: FC<any> = ({ children }) => {
  const widgetRef = useRef<HTMLDivElement | null>(null);

  const initWidgetDiv = useCallback((widget: any) => {
    widgetRef.current = widget;
  }, []);

  const value = useMemo(
    () => ({
      widgetRef,
      initWidgetDiv,
    }),
    [widgetRef, initWidgetDiv]
  );

  return (
    // @ts-expect-error
    <WidghetContext.Provider value={value}>{children}</WidghetContext.Provider>
  );
};

export const useWidgetContext = () => useContext(WidghetContext);
