import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ErrorBoundary } from "react-error-boundary";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ErrorBoundary
    fallback={
      <div>Something went wrong, please return to the previous screen</div>
    }
  >
    <App />
  </ErrorBoundary>
);

reportWebVitals();
