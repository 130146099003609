import { ReferralShareInformation } from "components/shared/ReferralShareInfo";
import { ReferralTree } from "components/ReferralTree";
// import { RefferralEarningsTable } from "components/ReferralTableHistory";

import { ReferralBonuses } from "components/ReferralBonuses";
import { Box } from "components/shared/Box";
import { ReferralInfoBlock } from "components/ReferralsInfoBlock";
import { ReferralSeniorPartner } from "components/ReferralSeniorPartner";
import { useUserContext } from "context/user.context";
import { Button } from "components/shared/Button";
import { useQuery } from "@tanstack/react-query";
import { getBinaryStatsReferral } from "requests";
import { Loader } from "components/shared/Loader";
import { Link } from "react-router-dom";

const ReferallPageStats = () => {
  const { user } = useUserContext();

  const { isLoading, data } = useQuery({
    queryKey: ["binaryStats"],
    queryFn: getBinaryStatsReferral,
  });

  if (isLoading) return <Loader />;

  return (
    <div className="grid grid-cols-1 gap-5 md:grid-cols-4">
      <Box className="col-span-1 flex flex-col md:col-span-2 md:flex-row">
        <div className="mb-5 md:mb-0 md:mr-5 w-full">
          <ReferralBonuses />
        </div>
      </Box>

      <Box className="col-span-1 md:col-span-1">
        <Button type="button" className="bg-main mt-4 text-black mb-5">
          LEFT Branch
        </Button>
        <ReferralInfoBlock data={data.leftLeg} />
      </Box>
      <Box className="col-span-1 md:col-span-1">
        <Button type="button" className="bg-main mt-4 text-black mb-5">
          RIGHT Branch
        </Button>
        <ReferralInfoBlock data={data.rightLeg} />
      </Box>
      <Box className="col-span-1 border-none !p-0 md:col-span-2">
        <ReferralTree />
      </Box>
      <div className="col-span-1 md:col-span-2">
        {+(user?.depositedAmount ?? 0) > 0 ? (
          <>
            <ReferralShareInformation
              withButton
              link={`https://smart-x.world?ref=${user?.referral_codeL}`}
              buttonText="Share referral link L"
            />
            <div className="mt-5">
              <ReferralShareInformation
                withButton
                link={`https://smart-x.world?ref=${user?.referral_codeR}`}
                buttonText="Share referral link R"
              />
            </div>
          </>
        ) : (
          <p className="mt-10 mb-10 text-center text-main">
            In order to receive referral links, make first{" "}
            <Link to="/finance">
              <strong>deposit</strong>{" "}
            </Link>
          </p>
        )}
        {user?.referred_by && (
          <Box className="mt-5">
            <ReferralSeniorPartner />
          </Box>
        )}
        {/* <Box className="mt-5">
          <RefferralEarningsTable />
        </Box> */}
      </div>
    </div>
  );
};

export default ReferallPageStats;
