import { HTMLInputTypeAttribute, useRef } from "react";
import classNames from "classnames";
import { UseFormRegister, WatchInternal } from "react-hook-form";
import { useToggle } from "hooks";

interface InputProps {
  label?: string | JSX.Element;
  id: string;
  type: HTMLInputTypeAttribute;
  placeholder?: string;
  LabelIcon?: JSX.Element;
  LabelHint?: JSX.Element;
  InputIcon?: JSX.Element;
  register: UseFormRegister<any>;
  errorMessage?: string;
  className?: string;
  containerClassname?: string;
  disabled?: boolean;
  maxLength?: number;
  minLength?: number;
  watch?: WatchInternal<string>;
  description?: string;
}

export const Input = ({
  placeholder,
  errorMessage,
  type,
  id,
  disabled,
  maxLength,
  minLength,
  register,
  watch,
  className,
  label,
  containerClassname,
  description,
}: InputProps) => {
  const prevValueRef = useRef();

  watch && watch(id);

  const [isShowPassword, togglePassword] = useToggle();

  return (
    <div className={classNames("flex flex-col", containerClassname)}>
      {label && (
        <label
          htmlFor={id}
          className="text-sm text-lGrey mb-[6px] cursor-pointer"
        >
          {label}
        </label>
      )}
      {type !== "password" ? (
        <input
          placeholder={placeholder}
          className={classNames(
            "w-full text-sm rounded-xl border border-lightBorder p-4 bg-lBlack outline-none focus:border-secondaryButton transition-all text-lGrey placeholder:text-placeholder placeholder:text-sm disabled:text-lightGrey",
            className && className,
            errorMessage && "!border-red "
          )}
          type={type}
          id={id}
          autoComplete="false"
          disabled={disabled}
          min={minLength}
          max={maxLength}
          step="any"
          formNoValidate
          {...register(id)}
        />
      ) : (
        <div className="relative">
          <input
            placeholder={placeholder}
            className={classNames(
              "w-full text-sm rounded-xl border border-lightBorder p-4 bg-lBlack outline-none focus:border-secondaryButton transition-all text-lGrey placeholder:text-placeholder placeholder:text-sm disabled:text-lightGrey",
              className && className,
              errorMessage && "!border-red"
            )}
            type={isShowPassword ? "text" : type}
            id={id}
            autoComplete="off"
            disabled={disabled}
            min={minLength}
            max={maxLength}
            {...register(id)}
          />
          {/* {showIcon && (
            <div
              onClick={togglePassword}
              className={classNames(
                "absolute top-[11px] right-[10px] text-white cursor-pointer",
                iconClassName && iconClassName
              )}
            >
              <img
                className="h-[15px] w-[25px]"
                src={isShowPassword ? "/icons/eyeClosed.svg" : "/icons/eye.svg"}
                alt="eye"
              />
            </div>
          )} */}
        </div>
      )}
      {errorMessage && (
        <div className="text-red text-[12px] mb-4">{errorMessage}</div>
      )}
      {description && (
        <p className="mb-5 mt-[10px] text-grey text-xs font-semibold">
          {description}
        </p>
      )}
    </div>
  );
};
