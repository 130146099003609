import { SocialAuth } from "components/SocialAuth";

const BNBChainId = "0x38";

const Login = () => {
  return (
    <div className="w-[320px] md:w-auto">
      <a href="https://smart-x.world" rel="noreferrer" target="_blank">
        <img
          className="w-[200px] m-auto mb-8 md:hidden"
          src="/icons/logo.svg"
          alt="SmartX logo"
        />
      </a>

      <h3 className="text-center text-white text-[27px] mb-8 leading-8">
        Start Your
        <br />
        Investment Today
      </h3>

      <div className="[&>*]:mt-4 w-full flex items-center justify-center">
        {/* <MetaMaskButton theme={"light"} color="white"></MetaMaskButton> */}
        <SocialAuth />
      </div>
    </div>
  );
};

export default Login;
