import { Button } from "components/shared/Button";
import { Box } from "components/shared/Box";
import { useUserContext } from "context/user.context";

export const ReferralBonuses = () => {
  const { user } = useUserContext();

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between md:flex-row flex-col mb-5">
        <div>
          <h3 className="text-lGrey font-bold mb-[6px]">
            Total referral <br /> bonuses:
          </h3>
          <h2 className="font-bold text-main text-2xl flex items-center">
            ${user?.totalReferralBonuses}
          </h2>
        </div>

        <div className="mt-5 flex md:mt-0">
          <div className="flex min-w-[100px] flex-col md:min-w-[118px]">
            <h3 className="mb-[6px] text-sm font-semibold text-lGrey">
              Direct bonus:
            </h3>
            <p className="font-bold text-lGrey">${user?.directBonus}</p>
          </div>
          <div className="mx-2 h-[45px] w-[1px] bg-lightBlack md:mx-5" />
          <div className="flex min-w-[100px] flex-col md:min-w-[118px]">
            <h3 className="mb-[6px] text-sm font-semibold text-lGrey">
              Binary income:
            </h3>
            <p className="font-bold text-lGrey">${user?.binaryIncome}</p>
          </div>
        </div>
      </div>
      <Box className="flex text-white justify-between items-center text-xl">
        <h3 className="font-bold">Binary turnover:</h3>
        <p>$ {user?.binaryTurnover}</p>
      </Box>
      <Button
        type="button"
        disabled
        className="bg-main mt-5 !w-full flex items-center justify-between px-10"
      >
        <p className="text-xl">Your LVL</p>
        <p className="font-bold text-xl">TIER {user?.membershipStatus}</p>
      </Button>
    </div>
  );
};
