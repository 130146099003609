import { useForm } from "react-hook-form";

import { useUserContext } from "context/user.context";
import { UserProps } from "types";
import { zodResolver } from "@hookform/resolvers/zod";

import { Input } from "components/shared/Input";

import { profileInfoValidation } from "const";
import { Box } from "components/shared/Box";
import { Button } from "components/shared/Button";

const Settings = () => {
  const { user, updateUser, isUserUpdating } = useUserContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserProps>({
    defaultValues: {
      ...user,
      telegram: user?.telegram ?? "",
    } as UserProps,
    resolver: zodResolver(profileInfoValidation),
  });

  const onSubmit = (formValues: UserProps) => {
    let userObj: any = {};

    if (formValues.telegram) {
      userObj.telegram = formValues.telegram;
    }
    updateUser({
      email: formValues.email,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      login: formValues.login,
      ...userObj,
    });
  };

  return (
    <>
      <Box>
        <h4 className="text-lGrey font-bold mb-5">General</h4>
        {user?.email === null && (
          <p className="text-red mb-5 text-2xl">
            In order to continue, enter your email
          </p>
        )}
        <Box className="border-none bg-lightBlack">
          {/* <div className="flex items-center mb-4">
            <div className="h-[120px] w-[120px] rounded-[40px] overflow-hidden mr-5">
              {renderAvatarPreview()}
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="avatar"
                className={classNames(
                  "block py-[6px] px-4 bg-boxBg rounded-lg text-lGrey cursor-pointer text-center",
                  isUserAvatarUpdating && "pointer-events-none"
                )}
              >
                Change avatar
                <input
                  hidden
                  type="file"
                  name="avatar"
                  id="avatar"
                  accept=".png, .jpg"
                  onChange={handleFileUpload}
                />
              </label>
              <div className="mt-[6px] text-grey text-xs font-semibold">
                JPG or PNG, 2 MB max file size
              </div>
            </div>
            {errors.avatar?.message && (
              <div className="text-center text-red mb-3">
                {errors.avatar?.message}
              </div>
            )}
          </div> */}
          <form onSubmit={handleSubmit(onSubmit)} className="mt-10 md:mt-0">
            <Input
              type="text"
              id="firstName"
              register={register}
              placeholder="First Name"
              errorMessage={errors.firstName?.message}
              label="First Name"
              className="mb-5"
            />
            <Input
              type="text"
              id="lastName"
              register={register}
              placeholder="Last Name"
              errorMessage={errors.lastName?.message}
              className="mb-5"
              label="Last Name"
            />
            <div className="flex flex-col">
              <Input
                type="text"
                id="login"
                register={register}
                placeholder="Login"
                errorMessage={errors.login?.message}
                label="Login"
                className="mb-5"
              />
            </div>
            <div className="flex flex-col">
              <Input
                type="email"
                id="email"
                register={register}
                placeholder="E-email"
                errorMessage={errors.email?.message}
                description="Changes are available to you"
                label="E-mail"
              />

              {/* <label className="text-sm text-lGrey mb-[6px]">E-mail</label>
              <div className="py-[14px] px-4 text-grey flex items-center justify-between border border-lightBorder rounded-xl">
                <input value={user?.email} className="w-full pr-4" />
                <a
                  className="text-main text-xs font-semibold"
                  href="mailto:info@smart-x.world"
                >
                  Support
                </a>
              </div>
              <p className="mb-5 mt-[10px] text-grey text-xs font-semibold">
                The change is available through technical support
              </p> */}
            </div>

            <Input
              type="text"
              id="telegram"
              register={register}
              placeholder="Telegram"
              errorMessage={errors.telegram?.message}
              description="Changes are available to you"
              label="Telegram"
            />
            <Button
              text="Save Changes"
              type="submit"
              className=" bg-main font-semibold text-sm "
              isLoading={isUserUpdating}
            />
          </form>
        </Box>
      </Box>
      {/* <Box className="mt-5">
        <h4 className="text-lGrey font-bold mb-5">Security</h4>
        <Box className="bg-lightBlack">
          <RecentLogins />
        </Box>

        <PinSection />

        <TwoFactorSection />

        <PasswordSection />

        <WhiteListSection />
      </Box> */}
    </>
  );
};

export default Settings;
