import Cookies from "js-cookie";
import { Auth } from "types";

export const clearSession = () => {
  Cookies.remove(Auth.TOKEN);
  Cookies.remove(Auth.PHPSESSID);
  Cookies.remove(Auth.REFRESH);
  Cookies.remove(Auth.TOTP);
  window.location.reload();
};
