import { Routes, Route } from "react-router-dom";

import AuthLayout from "layouts/auth.layout";
import { useAuth } from "hooks";
import { UserContextProvider } from "context/user.context";
import { WidgetContextProvider } from "context/widget.context";

import { ROUTES } from "config/routes";
import AppLayout from "layouts/app.layout";
import Settings from "pages/settings/Profile.page";
import { ProtectedRoute } from "components/shared/ProtectedRoutes";
import Login from "pages/auth/Login.page";
import SignUp from "pages/auth/SignUp.page";
import ReferallPage from "pages/referral/Referral.page";
import HomePage from "pages/Home.page";
import { Finance } from "pages/finance/Finance.page";
import TermsOfServices from "pages/TermsOfServices.page";
// import { useAccount } from "wagmi";

export const RootRouter = () => {
  const { token } = useAuth();
  const isAuthorized = !!token;

  return (
    <Routes>
      <Route
        path={ROUTES.auth}
        element={
          <ProtectedRoute isAllowed={!isAuthorized} redirectPath={"/"}>
            <AuthLayout />
          </ProtectedRoute>
        }
      >
        <Route path="login" element={<Login />} />
        {/* could be refactored */}
        <Route path="sign-up" element={<SignUp />} />

        <Route path="*" element={<div>Not found</div>} />
      </Route>

      <Route path="/terms-of-services" element={<TermsOfServices />} />
      <Route
        element={
          <ProtectedRoute isAllowed={isAuthorized}>
            <WidgetContextProvider>
              <UserContextProvider>
                <AppLayout />
              </UserContextProvider>
            </WidgetContextProvider>
          </ProtectedRoute>
        }
      >
        <Route path={ROUTES.home}>
          <Route index element={<HomePage />} />
          <Route path="settings">
            <Route index element={<Settings />} />
            <Route path="*" element={<div>not found</div>}></Route>
          </Route>
          <Route path="finance">
            <Route index element={<Finance />} />
            <Route path="*" element={<div>404 not found page</div>}></Route>
          </Route>
          <Route path="affiliate">
            <Route index element={<ReferallPage />} />
            <Route path="*" element={<div>404 not found page</div>}></Route>
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<div>404 not found page</div>} />
    </Routes>
  );
};
