import { ReferralShareInformation } from "components/shared/ReferralShareInfo";
import { useUserContext } from "context/user.context";
import { Link } from "react-router-dom";

export const ReferralIncome = () => {
  const { user } = useUserContext();

  return (
    <div>
      <div className="mb-5 flex justify-between">
        <div className="flex flex-col md:min-w-[153px]">
          <h3 className="mb-[6px] text-sm font-semibold text-lGrey">
            Total referrals
          </h3>
          <p className="font-bold text-lGrey">{user?.referralsCount}</p>
        </div>
        <div className="mx-5 h-[45px] w-[1px] bg-lightBlack" />
        <div className="flex flex-col md:min-w-[153px]">
          <h3 className="mb-[6px] text-sm font-semibold text-lGrey">
            Active referrals
          </h3>
          <p className="flex items-center font-bold text-lGrey">
            {user?.activeReferralsCount}{" "}
          </p>
        </div>
        <div className="mx-5 h-[45px] w-[1px] bg-lightBlack" />
        <div className="flex flex-col md:min-w-[153px]">
          <h3 className="mb-[6px] text-sm font-semibold text-lGrey">
            New referrals
          </h3>
          <p className="font-bold text-lGrey">{user?.newReferralsCount}</p>
        </div>
      </div>
      {+(user?.depositedAmount ?? 0) > 0 ? (
        <>
          <ReferralShareInformation
            withButton
            link={`https://smart-x.world?ref=${user?.referral_codeL}`}
            buttonText="Share referral link L"
          />
          <div className="mt-5">
            <ReferralShareInformation
              withButton
              link={`https://smart-x.world?ref=${user?.referral_codeR}`}
              buttonText="Share referral link R"
            />
          </div>
        </>
      ) : (
        <p className="mt-10 text-center text-main">
          In order to receive referral links, make first{" "}
          <Link to="/finance">
            <strong>deposit</strong>{" "}
          </Link>
        </p>
      )}
    </div>
  );
};
