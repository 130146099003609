import { Outlet, useNavigate } from "react-router-dom";
import classNames from "classnames";

import { useAuth, useToggle } from "hooks";
import { menuItems } from "const";

import FACheck from "pages/2faSecurity.page";
import { SidebarToggleItem } from "components/SidebarToggleItem";
import { Navbar } from "components/Navbar";
import { Loader } from "components/shared/Loader";
import { useUserContext } from "context/user.context";
import MobileMenu from "components/MobileMenu";
import { useEffect } from "react";
import { useAccount } from "wagmi";

const AppLayout = () => {
  const { isConnecting } = useAccount();
  const { handleSignout, TwoFactToken, token } = useAuth();
  const navigate = useNavigate();

  const { isProfileLoading, profileError, user, isAppSettingsLoading } =
    useUserContext();

  const [showSidebar, toggleSidebar, setSidebarView] = useToggle();

  const handleCloseSidebar = () => setSidebarView(false);

  useEffect(() => {
    if (user?.email === null) {
      navigate("/settings");
    }
  }, [user, navigate]);

  if (isConnecting && isAppSettingsLoading && isProfileLoading)
    return (
      <div className="flex min-h-screen bg-mainBg">
        <Loader className="!bg-white" />
      </div>
    );

  if (profileError) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-mainBg">
        <h2 className="text-white">Something went wrong while loading</h2>
      </div>
    );
  }

  if (TwoFactToken && !token) {
    return <FACheck />;
  }

  return (
    <div className="relative flex overflow-auto lg:min-h-screen">
      <div
        className={classNames(
          "fixed z-[49] h-full w-full bg-black bg-opacity-60 transition-all",
          showSidebar ? "block" : "hidden"
        )}
        onClick={toggleSidebar}
      />
      <aside
        className={classNames(
          "fixed z-50 h-full w-full max-w-[200px] self-stretch overflow-y-auto bg-sidebar py-5 transition-all xl:relative xl:left-0 xl:h-auto",
          showSidebar ? "left-0" : "-left-full"
        )}
      >
        <div className="relative mb-5 xl:mb-0"></div>
        <div className="my-[30px] hidden flex-col p-5 xl:flex">
          <a
            href={'href="https://smart-x.world"'}
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="w-[200px] m-auto mb-8"
              src="/icons/logo.svg"
              alt="SmartX logo"
            />
          </a>
          <h2 className="mb-[6px] text-sm text-white text-ellipsis overflow-hidden">
            Hello, {user?.firstName}
          </h2>
          <p className="text-xs font-semibold text-grey text-ellipsis overflow-hidden">
            {user?.login}
          </p>
        </div>

        <ul>
          {menuItems.map((item) => (
            <SidebarToggleItem
              key={item.title}
              item={item}
              toggleSidebar={handleCloseSidebar}
            />
          ))}
        </ul>

        <button
          className="mt-5 ml-5 text-grey text-sm"
          type="button"
          onClick={handleSignout}
        >
          Logout
        </button>
      </aside>
      <main className="mb-24 flex w-full flex-col self-stretch bg-mainBg px-[10px] text-defaultText lg:px-[30px] xl:mb-5 xl:w-[calc(100%-200px)]">
        <Navbar toggleSidebar={toggleSidebar} />

        <Outlet />
      </main>
      <MobileMenu />
    </div>
  );
};

export default AppLayout;
