import { FC } from "react";
import { Box } from "components/shared/Box";
import { useUserContext } from "context/user.context";
import { copyClipboard } from "utils";

import { Button } from "../Button";
import { ShareArrowIcon } from "../Icons";

// TODO: move to env
const href = "smart-x.world";

interface ReferralShareInformationProps {
  withButton?: boolean;
  link: string;
  buttonText?: string;
}

export const ReferralShareInformation: FC<ReferralShareInformationProps> = ({
  withButton,
  link,
  buttonText = "Share referral link",
}) => {
  const { user } = useUserContext();

  return (
    <Box className="bg-lightBlack border-none">
      <h3 className="mb-4 text-lGrey font-semibold">Your referral link:</h3>
      <div className="flex flex-col md:flex-row">
        <div className="flex justify-between items-center rounded-[12px] bg-lBlack border border-boxBg p-4 w-full">
          <input
            disabled
            value={link}
            className="text-sm text-lGrey w-full pr-5 "
          />
        </div>
        {/* TODO: figure out what exactly should be done with this button  */}
        {withButton && (
          <Button
            type="button"
            className="border-main border bg-dMain md:max-w-[200px] mt-[10px] md:mt-0 md:ml-[10px] flex items-center justify-center"
            onClick={() => {
              copyClipboard(link);
            }}
          >
            <p className="text-main font-semibold text-sm mr-[10px]">
              {buttonText}
            </p>
            <ShareArrowIcon />
          </Button>
        )}
      </div>
    </Box>
  );
};
